import { StarIcon } from "@heroicons/vue/20/solid";

export const understandReputation = {
  order: 0,
  name: "Understand reputation",
  keywords: "bronze silver gold stake staking reward datapoint price pricing",
  subcategory: "Build credibility",
  icon: StarIcon,
  iconColor: "text-yellow-500",
  markdown: `# Understand reputation

  Tower Hunt's public data layer is a powerful resource for users, giving them a head start on the often difficult job of tracking market activity. The public data layer is most helpful when it is accurate and comprehensive. To achieve these goals, Tower Hunt must reward good contributors and discourage bad actors. The reputation score is part of the solution.

  {% callout type="tip" %}
  **Tip:** Improving your reputation requires you to put money at risk. Make sure you learn {% inlineRouterLink %}why Tower Hunt uses staking{% /inlineRouterLink %} so you can earn more.
  {% /callout %}

  ## Reputation levels

  Below is a complete list of the reputation levels available on Tower Hunt:

  | **Reputation** | **Score** |
  |---|---|
  | {% inlineReputationIcon reputation="establishing" /%} Establishing | 0 |
  | {% inlineReputationIcon reputation="bronze" /%} Bronze | 100 |
  | {% inlineReputationIcon reputation="silver" /%} Silver | 1,000 |
  | {% inlineReputationIcon reputation="gold" /%} Gold | 5,000 |

  ## What makes reputation change?

  Reputation changes whenever data is validated or invalidated. There are two ways that this happens:

  - A proposed change is {% inlineRouterLink %}accepted or rejected by consensus validation{% /inlineRouterLink %}.
  - A user successfully {% inlineRouterLink %}proves knowledge of an unlockable datapoint{% /inlineRouterLink %}.

  ## What reputation affects

  Increasing your reputation grants powerful economic benefits across the platform:

  ### Database editing permissions

  In order to make changes in Tower Hunt, you must be a *reputable user* according to the following criteria:

  - Reputation score greater than or equal to 0.0.
  - 60% or more of recent contributions are correct. Recent contributions include:
    - Up to 50 database changes with a validation outcome (ex. accepted or rejected)
    - Up to 20 validation votes on changes with a validation outcome (ex. accepted or rejected)

  ### Staking costs

  Higher reputation levels lower your staking costs. This reflects the lower risk that you will add bad data to Tower Hunt.

  | **Reputation** | **Author** | **Validator**|
  |---|---|---|
  | {% inlineReputationIcon reputation="establishing" /%} Establishing | 16x | 8x |
  | {% inlineReputationIcon reputation="bronze" /%} Bronze | 8x | 4x |
  | {% inlineReputationIcon reputation="silver" /%} Silver | 4x | 2x |
  | {% inlineReputationIcon reputation="gold" /%} Gold | 2x | 1x |

  ### Validation rewards

  Higher reputation levels boost your validation rewards. This reflects the value that your data adds to Tower Hunt's users.

  | **Reputation** | **Author** | **Validator**|
  |---|---|---|
  | {% inlineReputationIcon reputation="establishing" /%} Establishing | 2x | 1x |
  | {% inlineReputationIcon reputation="bronze" /%} Bronze | 4x | 2x |
  | {% inlineReputationIcon reputation="silver" /%} Silver | 8x | 4x |
  | {% inlineReputationIcon reputation="gold" /%} Gold | 16x | 8x |

  ### Datapoint pricing

  Higher reputation levels increase the value of related datapoints. This reflects the heightened credibility of this data.

  | **Avg contributor reputation** | **Multiplier**|
  |---|---|
  | {% inlineReputationIcon reputation="establishing" /%} Establishing | 1x |
  | {% inlineReputationIcon reputation="bronze" /%} Bronze | 2x |
  | {% inlineReputationIcon reputation="silver" /%} Silver | 3x |
  | {% inlineReputationIcon reputation="gold" /%} Gold | 4x |
  `,
};
